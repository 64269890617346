import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'; // To read query parameters
import { db } from './firebase';
import { ref, get} from 'firebase/database'; // Firebase utilities if required
import { FaDownload, FaExternalLinkAlt } from 'react-icons/fa';


const ResumeGenerator = () => {
    const [searchParams] = useSearchParams(); // Get query parameters
    const jobId = searchParams.get('jobId'); // Get the 'job' parameter from the URL
  
    const [progress, setProgress] = useState(0); // For showing progress
    const [isProcessing, setIsProcessing] = useState(true); // To track if the job is still processing
    const [jobStatus, setJobStatus] = useState(null); // To track the job status (in-progress, success, error)
    const [downloadLink, setDownloadLink] = useState(null); // Store download link after job completes
    const [coverLetterDownloadLink, setCoverLetterDownloadLink] = useState(null); // Store download link after job completes
    const [jobData, setJobData] = useState(null); // Store the job data fetched from your database
    useEffect(() => {
        // Simulate fetching job data from Firebase (Replace with real Firebase fetching logic)
        const fetchJobData = async () => {
          try {
            // Fetch job data by jobId (Assuming you fetch job data from your DB)
            const jobsRef = ref(db, 'jobs/' + jobId); // Replace with your actual Firebase reference
            const jobSnapshot = await get(jobsRef);
    
            if (jobSnapshot.exists()) {
              const jobEntry = jobSnapshot.val();
              setJobData(jobEntry); // Save the fetched job data
              processJob(jobEntry); // Start processing the job
            } else {
              console.error('No job found with this jobId.');
              setJobStatus('error');
            }
          } catch (error) {
            console.error('Error fetching job:', error);
            setJobStatus('error');
          }
        };
    
        // Function to process the job using the tailor_resume API
        const processJob = async (jobEntry) => {
          try {
            const { jobDescription, companyName, jobTitle } = jobEntry;
            const outputFilename = `${companyName}_${jobTitle}`;
    
            // Call the tailor_resume API
            const response = await fetch('https://admin.aracademy360.com/tailor_resume_single', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                job_description: jobDescription,
                output_filename: outputFilename,
                company_name: companyName,
                job_title: jobTitle,
                job_id: jobId
              }),
            });
    
            const result = await response.json();
    
            if (result.status === 'success') {
              // Start tracking the progress using EventSource (Server-Sent Events)
              const eventSource = new EventSource(`https://admin.aracademy360.com/progress/${result.task_id}`);
    
              eventSource.onmessage = (event) => {
                const data = JSON.parse(event.data);
                setProgress(data.progress); // Update progress
    
                if (data.progress === 100) {
                  eventSource.close(); // Stop listening to progress updates
                  setIsProcessing(false); // Mark the job as finished
                  setJobStatus('success'); // Mark status as success
                  setDownloadLink(`https://admin.aracademy360.com/api/resumes/download/${outputFilename}.docx`); // Set download link
                  setCoverLetterDownloadLink(`https://admin.aracademy360.com/api/resumes/download/${outputFilename}_CL.docx`); // Set download link
                }
              };
    
              eventSource.onerror = () => {
                eventSource.close();
                setJobStatus('error');
                setIsProcessing(false);
                console.error('Error during job processing.');
              };
            } else {
              setJobStatus('error');
              setIsProcessing(false);
              alert(result.message);
            }
          } catch (error) {
            console.error('Error processing job:', error);
            setJobStatus('error');
            setIsProcessing(false);
          }
        };
    
        // Fetch the job data and start processing when the component mounts
        fetchJobData();
      }, [jobId]);
    
      return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
          <h1 className="text-xl font-bold mb-4">
          {/* Show the job title and company name when available, otherwise show the jobId */}
          {jobData && jobData.jobLink && (
  <div className="mb-4 text-center">
    <a
      href={jobData.jobLink}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center space-x-2"
    >
      <FaExternalLinkAlt /> <span>View Job Posting</span>
    </a>
  </div>
)}
          {jobData ? (
            <>
              Processing Job: {jobData.jobTitle} at {jobData.companyName}
            </>
          ) : (
            `Processing Job: ${jobId}`
          )}
        </h1>
            {isProcessing ? (
              <>
                {/* Show progress bar while job is processing */}
                <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                  <div
                    className="bg-blue-600 h-4 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <p className="text-center">{progress}% completed</p>
              </>
            ) : jobStatus === 'success' ? (
              <>
                {/* Show download button if processing is complete */}
                <div className="text-center">
                  <a
                    href={downloadLink}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center space-x-2"
                    download
                  >
                    <FaDownload /> <span>Download Resume</span>
                  </a>
                </div>
                {/* Show download button if processing is complete */}
                <br/><br/>
                <div className="text-center">
                  <a
                    href={coverLetterDownloadLink}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center space-x-2"
                    download
                  >
                    <FaDownload /> <span>Download CoverLetter</span>
                  </a>
                </div>
              </>
            ) : jobStatus === 'error' ? (
              <p className="text-red-500 text-center">Error processing the job. Please try again.</p>
            ) : null}
          </div>
        </div>
      );
    };
export default ResumeGenerator;
