import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import {
  ref,
  onValue,
  update,
  remove
} from 'firebase/database';
import { FaPlay, FaTrash, FaStop, FaEye, FaDownload, FaExternalLinkAlt , FaChartBar, FaSpinner } from 'react-icons/fa'; // Add FaSpinner for analyzing
import EditableField from './EditableField'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom'; // Import useNavigate to programmatically navigate

const ListPage = () => {
  const [jobs, setJobs] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(null);
  const [progress, setProgress] = useState({});
  const [processingJobId, setProcessingJobId] = useState(null);
  const [completedJobs, setCompletedJobs] = useState([]);
  const [timeFilter, setTimeFilter] = useState('All');
  const [statusFilter, setStatusFilter] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [easyApplyFilter, setEasyApplyFilter] = useState('All');
  const [sortOption, setSortOption] = useState('Date'); // New state variable for sorting

  const [jobInProgress, setJobInProgress] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Processing queue and state
  const [processingQueue, setProcessingQueue] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const paginatedJobs = jobs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Assuming you have the jobs data and other state setup
  const navigate = useNavigate(); // Using useNavigate to redirect

  const openJobProcessInNewTab = (jobId) => {
    // Generate the route path
    const path = `/?jobId=${jobId}`;
    
    // Open the path in a new tab
    window.open(`${window.location.origin}${path}`, '_blank');
  };

  useEffect(() => {
    const jobsRef = ref(db, 'jobs');
  
    onValue(jobsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log("Original Data:", data); // Log the original data to inspect datePosted
  
        // No sorting here, we'll sort after filtering
        setJobs(Object.entries(data));
      } else {
        setJobs([]);
      }
    });
  
    const intervalId = setInterval(() => {
      fetch('https://admin.aracademy360.com/current_job')
        .then((response) => response.json())
        .then((data) => {
          if (data.job_in_progress) {
            setJobInProgress({
              companyName: data.company_name,
              jobTitle: data.job_title,
            });
          } else {
            setJobInProgress(null);
          }
        })
        .catch((error) => {
          console.error('Error fetching current job:', error);
        });
    }, 5000); // 5000 milliseconds = 5 seconds
  
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to handle ATS analysis request
  const handleAnalyzeResume = async (jobId, jobDescription) => {
    // Update the ats_skills_status to 'in-progress' in Firebase
    const jobRef = ref(db, `jobs/${jobId}`);
    try {
      await update(jobRef, { ats_skills_status: 'in-progress' });

      // Call the analyze_resume API
      const response = await fetch('https://admin.aracademy360.com/analyze_resume', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          job_description: jobDescription,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const atsSkills = await response.json();

      // Update the job entry with ats_skills and set ats_skills_status to 'completed'
      await update(jobRef, {
        ats_skills: atsSkills,
        ats_skills_status: 'completed',
      });
    } catch (error) {
      console.error('Error during ATS skills analysis:', error);
      // Update the ats_skills_status to 'error'
      await update(jobRef, { ats_skills_status: 'error' });
    }
  };

  const toggleAccordion = (id) => {
    setAccordionOpen(accordionOpen === id ? null : id);
  };
  const handleStopJob = () => {
    fetch('https://admin.aracademy360.com/stop_job', {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          alert('Current job has been stopped.');
          setJobInProgress(null);
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error('Error stopping job:', error);
      });
  };
  const getStatusColor = (status) => {
    switch (status) {
      case 'success':
        return 'bg-green-500';
      case 'in-progress':
        return 'bg-yellow-500';
      case 'failure':
        return 'bg-red-500';
      case 'pending':
        return 'bg-blue-300';
      default:
        return 'bg-gray-300';
    }
  };

  // Updated function to format datePosted as relative time
  const formatRelativeTime = (datePosted, timestamp) => {
    const dateToUse = datePosted || timestamp || Date.now();
    const now = Date.now();
    const adjustedDatePosted = dateToUse - (1000 * 60 * 60 * 2);
    const difference = now - adjustedDatePosted;

    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(difference / (1000 * 60));
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    // Adjusted the logic to give more detailed time formatting
    if (seconds < 60) {
      return `${seconds} sec${seconds !== 1 ? 's' : ''} ago`;
    } else if (minutes < 60) {
      return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  };

  const handleProcessJob = (jobDescription, companyName, jobTitle, jobId) => {
    return new Promise(async (resolve, reject) => {
      if (processingJobId) return reject('Another job is being processed');

      setProcessingJobId(jobId);
      setProgress((prev) => ({ ...prev, [jobId]: 0 }));

      const jobRef = ref(db, `jobs/${jobId}`);
      try {
        const outputFilename = `${companyName}_${jobTitle}`;

        // Fetch existing resumes
        const resumesResponse = await fetch('https://admin.aracademy360.com/api/resumes_list');
        const resumesList = await resumesResponse.json();

        // Check if outputFilename already exists
        const filenameToCheck = `${outputFilename}.docx`; // Since the resumes have .docx extension
        const filenameExists = resumesList.some((resume) => resume.name === filenameToCheck);

        if (filenameExists) {
          alert(`Resume "${filenameToCheck}" already exists. Skipping processing.`);
          setProcessingJobId(null);

          // Update job status back to 'pending' or you can set a new status like 'skipped'
          update(jobRef, { status: 'success' });

          resolve(); // Resolve the promise to continue with the next job
          return; // Exit the function to skip processing
        }
      } catch (e) {
        setProcessingJobId(null);
        alert('Failed to start resume tailoring.');
        reject('Failed to start resume tailoring.');
      }
      update(jobRef, { status: 'in-progress' });


      try {
        const outputFilename = `${companyName}_${jobTitle}`;

        // Fetch existing resumes
        const resumesResponse = await fetch('https://admin.aracademy360.com/api/resumes_list');
        const resumesList = await resumesResponse.json();

        // Check if outputFilename already exists
        const filenameToCheck = `${outputFilename}.docx`; // Since the resumes have .docx extension
        const filenameExists = resumesList.some((resume) => resume.name === filenameToCheck);

        if (filenameExists) {
          alert(`Resume "${filenameToCheck}" already exists. Skipping processing.`);
          setProcessingJobId(null);

          // Update job status back to 'pending' or you can set a new status like 'skipped'
          update(jobRef, { status: 'pending' });

          resolve(); // Resolve the promise to continue with the next job
          return; // Exit the function to skip processing
        }

        // Proceed with processing if the file doesn't exist
        const response = await fetch('https://admin.aracademy360.com/tailor_resume', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            job_description: jobDescription,
            output_filename: outputFilename,
            company_name: companyName,
            job_title: jobTitle,
            job_id: jobId
          }),
        });

        const result = await response.json();
        if (result.status === 'success') {
          const eventSource = new EventSource(`https://admin.aracademy360.com/progress/${result.task_id}`);

          eventSource.onmessage = async (event) => {
            const data = JSON.parse(event.data);
            setProgress((prev) => ({ ...prev, [jobId]: data.progress }));

            if (data.progress === 100) {
              eventSource.close();
              setProcessingJobId(null);

              update(jobRef, { status: 'success' });

              setCompletedJobs((prev) => [...prev, jobId]);

              resolve(); // Resolve the promise
            }
          };

          eventSource.onerror = async () => {
            eventSource.close();
            setProcessingJobId(null);

            reject('Error during processing');
          };
        } else {
          setProcessingJobId(null);
          alert(result.message);
          reject(result.message);
        }
      } catch (e) {
        setProcessingJobId(null);
        alert('Failed to start resume tailoring.');
        reject('Failed to start resume tailoring.');
      }
    });
  };

  const getTimeThreshold = (timeFilter) => {
    const now = Date.now();
    switch (timeFilter) {
      case 'Past hour':
        return now - 1 * 60 * 60 * 1000;
      case 'Past 5 hours':
        return now - 5 * 60 * 60 * 1000;
      case 'Past 10 hours':
        return now - 10 * 60 * 60 * 1000;
      case 'Past 24 hours':
        return now - 24 * 60 * 60 * 1000;
      case 'Past 2 days':
        return now - 2 * 24 * 60 * 60 * 1000;
      case 'Past 3 days':
        return now - 3 * 24 * 60 * 60 * 1000;
      case 'Past 4 days':
        return now - 4 * 24 * 60 * 60 * 1000;
      case 'Past 5 days':
        return now - 5 * 24 * 60 * 60 * 1000;
      default:
        return 0;
    }
  };

  const handleDeleteSelectedJobs = () => {
    if (selectedJobs.length === 0) return;
    const confirmDelete = window.confirm('Are you sure you want to delete the selected jobs?');
    if (!confirmDelete) return;

    selectedJobs.forEach((jobId) => {
      const jobRef = ref(db, `jobs/${jobId}`);
      remove(jobRef);
    });
    setSelectedJobs([]);
  };

  const handleDeleteOlderThan = (timeRange) => {
    if (!timeRange) return;
    const confirmDelete = window.confirm(`Are you sure you want to delete items older than ${timeRange}?`);
    if (!confirmDelete) return;
    const timeThreshold = getTimeThreshold(timeRange);
    jobs.forEach(([id, job]) => {
      if (job.datePosted < timeThreshold) {
        const jobRef = ref(db, `jobs/${id}`);
        remove(jobRef);
      }
    });
  };

  const handleProcessSelectedJobs = async () => {
    if (selectedJobs.length === 0) return;

    // Set processing queue
    setProcessingQueue(selectedJobs);

    // Start processing
    setIsProcessing(true);
  };

  // Process jobs one by one
  useEffect(() => {
    const processNextJob = async () => {
      if (processingQueue.length === 0) {
        // No more jobs to process
        setIsProcessing(false);
        return;
      }

      const nextJobId = processingQueue[0];
      const jobEntry = jobs.find(([id, job]) => id === nextJobId);
      if (!jobEntry) {
        // Job not found, remove it from the queue and continue
        setProcessingQueue((prevQueue) => prevQueue.slice(1));
        return;
      }
      const [jobId, job] = jobEntry;

      try {
        await handleProcessJob(job.jobDescription, job.companyName, job.jobTitle, jobId);

        // After processing, remove the job from the queue and process the next one
        setProcessingQueue((prevQueue) => prevQueue.slice(1));
      } catch (error) {
        console.error(`Error processing job ${jobId}:`, error);
        // Remove the job from the queue and continue
        setProcessingQueue((prevQueue) => prevQueue.slice(1));
      }
    };

    if (isProcessing && processingJobId === null) {
      // If we are processing and no job is currently being processed, start processing the next job
      processNextJob();
    }
  }, [processingQueue, isProcessing, processingJobId, jobs]);

  const timeThreshold = getTimeThreshold(timeFilter);

  // Boolean Search Functions
  const parseSearchQuery = (query) => {
    const tokens = [];
    const regex = /\s*(AND|OR|NOT|\(|\)|[^()\s]+)\s*/gi;
    let match;
    while ((match = regex.exec(query)) !== null) {
      tokens.push(match[1]);
    }
    return tokens;
  };

  const parseBooleanExpression = (tokens) => {
    let index = 0;

    const parseExpression = () => {
      let left = parseTerm();

      while (
        index < tokens.length &&
        (tokens[index].toUpperCase() === 'AND' || tokens[index].toUpperCase() === 'OR')
      ) {
        const operator = tokens[index++].toUpperCase();
        const right = parseTerm();

        left = {
          type: operator,
          left,
          right,
        };
      }
      return left;
    };

    const parseTerm = () => {
      if (tokens[index] && tokens[index].toUpperCase() === 'NOT') {
        index++;
        const term = parseTerm();
        return {
          type: 'NOT',
          term,
        };
      } else if (tokens[index] === '(') {
        index++;
        const expr = parseExpression();
        if (tokens[index] !== ')') {
          throw new Error('Missing closing parenthesis');
        }
        index++;
        return expr;
      } else {
        const term = tokens[index++];
        return {
          type: 'TERM',
          value: term,
        };
      }
    };

    return parseExpression();
  };const evaluateExpression = (expr, job) => {
    switch (expr.type) {
      case 'TERM':
        const term = expr.value.toLowerCase();
        return (
          (job.companyName?.toLowerCase().includes(term) || '') ||
          (job.jobTitle?.toLowerCase().includes(term) || '') ||
          (job.jobDescription?.toLowerCase().includes(term) || '') ||
          (job.skills?.join(' ').toLowerCase().includes(term) || '') ||
          (job.mandatoryRequirements?.join(' ').toLowerCase().includes(term) || '')
        );
      case 'NOT':
        return !evaluateExpression(expr.term, job);
      case 'AND':
        return evaluateExpression(expr.left, job) && evaluateExpression(expr.right, job);
      case 'OR':
        return evaluateExpression(expr.left, job) || evaluateExpression(expr.right, job);
      default:
        return false;
    }
  };
  
  const collectTerms = (expr) => {
    const terms = [];
    const collect = (node) => {
      if (node.type === 'TERM') {
        terms.push(node.value);
      } else if (node.type === 'NOT') {
        collect(node.term);
      } else if (node.type === 'AND' || node.type === 'OR') {
        collect(node.left);
        collect(node.right);
      }
    };
    collect(expr);
    return terms;
  };

  const escapeRegExp = (string) => {
    if (typeof string !== 'string') return '';
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const highlightTextWithTerms = (text, terms) => {
    if (!text || typeof text !== 'string') return null;
    if (!terms || terms.length === 0) return text;

    // Filter out undefined or non-string terms
    const validTerms = terms.filter((term) => typeof term === 'string');

    const regex = new RegExp(`(${validTerms.map((term) => escapeRegExp(term)).join('|')})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="bg-yellow-200">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  let termsToHighlight = [];
  if (searchQuery) {
    try {
      const tokens = parseSearchQuery(searchQuery);
      const expr = parseBooleanExpression(tokens);
      termsToHighlight = collectTerms(expr);
    } catch (error) {
      console.error('Error parsing search query:', error);
    }
  }

  // Compute filtered jobs
  let filteredJobs = jobs
  .filter(([id, job]) => {
    const dateToUse = job.datePosted || job.timestamp || 0;
    return dateToUse >= timeThreshold;
  })
  .filter(([id, job]) => {
    if (statusFilter === 'All') return true;
    return job.status === statusFilter;
  })
  .filter(([id, job]) => {
    if (easyApplyFilter === 'All') return true;
    if (easyApplyFilter === 'Easy Apply') return job.easyApply === true;
    return true;
  })
  .filter(([id, job]) => {
    if (!searchQuery) return true;
    try {
      const tokens = parseSearchQuery(searchQuery);
      const expr = parseBooleanExpression(tokens);
      return evaluateExpression(expr, job);
    } catch (error) {
      console.error('Error parsing search query:', error);
      return false;
    }
  });

  // Sort the filtered jobs based on the selected sort option
  filteredJobs = filteredJobs.sort((a, b) => {
    const jobA = a[1];
    const jobB = b[1];

    if (sortOption === 'ScoreHigh') {
      const scoreA = jobA.ats_skills?.score ?? -Infinity;
      const scoreB = jobB.ats_skills?.score ?? -Infinity;
      return scoreB - scoreA; // High to Low
    } else if (sortOption === 'ScoreLow') {
      const scoreA = jobA.ats_skills?.score ?? Infinity;
      const scoreB = jobB.ats_skills?.score ?? Infinity;
      return scoreA - scoreB; // Low to High
    } else {
      // Default to sorting by datePosted descending
      const dateA = new Date(jobA.datePosted).getTime() || 0;
      const dateB = new Date(jobB.datePosted).getTime() || 0;
      return dateB - dateA;
    }
  });

  // Compute total pages
  const totalPages = Math.ceil(filteredJobs.length / itemsPerPage);

  // Reset current page when filters or sort option change
  useEffect(() => {
    setCurrentPage(1);
  }, [timeFilter, statusFilter, searchQuery, sortOption]);

  // Ensure current page is within valid range
  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Get jobs for current page
  const beautifyText = (text) => {
    // Check if the text is defined and is a string, otherwise return an empty string or default value
    if (!text || typeof text !== 'string') {
      return '';
    }
    
    return text
      .toLowerCase() // Convert the text to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  return (
    <div className="min-h-screen bg-gray-50 p-4 flex flex-col items-center">
      {/* Display Current Job in Progress */}
      {jobInProgress && (
        <div className="mb-4 w-full max-w-6xl p-4 bg-yellow-100 border border-yellow-300 rounded">
          <p>
            <strong>Currently Processing:</strong> {jobInProgress.companyName} - {jobInProgress.jobTitle}
          </p>
          <button
            onClick={handleStopJob}
            className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center space-x-2 mr-2`}>
            <FaStop className="mr-2" /> Stop
            </button>
        </div>
      )}

      {/* Filters and Search */}
      <div className="mb-4 w-full max-w-6xl">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          {/* Time Filter */}
          <div className="mb-4 md:mb-0 flex-1 md:mr-2">
            <label htmlFor="timeFilter" className="mr-2">
              Filter by time:
            </label>
            <select
              id="timeFilter"
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
            >
              <option value="All">All</option>
              <option value="Past hour">Past hour</option>
              <option value="Past 5 hours">Past 5 hours</option>
              <option value="Past 10 hours">Past 10 hours</option>
              <option value="Past 24 hours">Past 24 hours</option>
              <option value="Past 2 days">Past 2 days</option>
              <option value="Past 3 days">Past 3 days</option>
              <option value="Past 4 days">Past 4 days</option>
              <option value="Past 5 days">Past 5 days</option>
            </select>
          </div>

          {/* Status Filter */}
          <div className="mb-4 md:mb-0 flex-1 md:mr-2">
            <label htmlFor="statusFilter" className="mr-2">
              Filter by status:
            </label>
            <select
              id="statusFilter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
            >
              <option value="All">All</option>
              <option value="pending">Pending</option>
              <option value="in-progress">In Progress</option>
              <option value="success">Success</option>
              <option value="failure">Failure</option>
            </select>
          </div>

          {/* Sort Option */}
          <div className="mb-4 md:mb-0 flex-1 md:mr-2">
            <label htmlFor="sortOption" className="mr-2">
              Sort by:
            </label>
            <select
              id="sortOption"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
            >
              <option value="Date">Date</option>
              <option value="ScoreHigh">Score High to Low</option>
              <option value="ScoreLow">Score Low to High</option>
            </select>
          </div>

          {/* Delete Older Than */}
          <div className="mb-4 md:mb-0 flex-1 md:mr-2">
            <label htmlFor="deleteTimeFilter" className="mr-2">
              Delete Items:
            </label>
            <select
              id="deleteTimeFilter"
              onChange={(e) => handleDeleteOlderThan(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
            >
              <option value="">Select</option>
              <option value="Past 24 hours">Past 24 hours</option>
              <option value="Past 2 days">Past 2 days</option>
              <option value="Past 3 days">Past 3 days</option>
              <option value="Past 4 days">Past 4 days</option>
              <option value="Past 5 days">Past 5 days</option>
            </select>
          </div>
          <div className="mb-4 md:mb-0 flex-1 md:mr-2">
            <label htmlFor="easyApplyFilter" className="mr-2">Easy Apply:</label>
            <select
              id="easyApplyFilter"
              value={easyApplyFilter}
              onChange={(e) => setEasyApplyFilter(e.target.value)}
              className="border border-gray-300 rounded p-2 w-full"
            >
              <option value="All">All</option>
              <option value="Easy Apply">Easy Apply</option>
            </select>
          </div>

        </div>
        

        {/* Search Bar */}
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search by company, job title, description, skills, mandatory requirements"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded p-2 w-full"
          />
        </div>

        {/* Action Buttons */}
        <div className="mt-4 flex justify-end p-2">
          <button
            onClick={handleProcessSelectedJobs}
            disabled={selectedJobs.length === 0 || isProcessing || jobInProgress}
            className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center space-x-2 mr-2 ${
              selectedJobs.length === 0 || isProcessing || jobInProgress ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
              <FaPlay className="mr-2" /> {jobInProgress ? 'Busy Processing' : 'Process Selected'}
          </button>
          <button
            onClick={handleDeleteSelectedJobs}
            disabled={selectedJobs.length === 0}
            className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center space-x-2 ${
              selectedJobs.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FaTrash className="mr-2" /> Delete
          </button>
      </div>

      <div className="w-full max-w-6xl">
        {filteredJobs.length > 0 ? (
          paginatedJobs.map(([id, job]) => {
            const filename = `${job.companyName}_${job.jobTitle}`;
            return (
              <div key={id} className="mb-4 border border-gray-200 rounded-lg shadow-sm">
              <div className={`flex items-center justify-between p-4 ${getStatusColor(job.status)} text-black rounded-t-lg font-semibold`}>
                <div className="flex items-center w-full">
                    <input
                      type="checkbox"
                      className="form-checkbox h-6 w-6 text-blue-500 border-gray-300 rounded-lg focus:ring-blue-400 focus:ring-2 mr-2" // Custom checkbox styling

                      checked={selectedJobs.includes(id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedJobs([...selectedJobs, id]);
                        } else {
                          setSelectedJobs(selectedJobs.filter((jobId) => jobId !== id));
                        }
                      }}
                    />

                    <div onClick={() => toggleAccordion(id)} className="cursor-pointer">

                      {/* Company Name */}
                      <span className="text-white font-bold">
                        {beautifyText(job.companyName)}
                      </span>
                      
                      {/* Job Title */}
                      <span className="text-white ml-2">
                        - {beautifyText(job.jobTitle)}
                      </span>
                      
                      {/* Date */}
                      <span className="text-black-400 ml-2">
                        - {formatRelativeTime(job.datePosted, job.timestamp)}
                      </span>                   
                      {/* Display ATS status */}
                      {job.ats_skills_status === 'in-progress' && (
                        <span className="ml-2 text-yellow-500">Analysis in progress...</span>
                      )}
                      {job.ats_skills_status === 'error' && (
                        <span className="ml-2 text-red-500">Analysis error</span>
                      )}
                    </div>
                    {/* Add Analyze Resume button if score is not present */}
                    <div className="ml-auto flex space-x-2">
                      {/* Show Get Score Button if score is not present */}
                      {!job.ats_skills?.score && (
                      <button
                        onClick={() => handleAnalyzeResume(id, job.jobDescription)}
                        className="bg-green-500 text-white px-2 py-1 rounded flex items-center space-x-2"
                        disabled={job.ats_skills_status === 'in-progress'}
                      >
                        {job.ats_skills_status === 'in-progress' ? (
                          <>
                            <FaSpinner /> {/* Icon for Get Score */}
                          </>
                        ) : (
                          <>
                            <FaChartBar /> {/* Icon for Get Score */}
                          </>
                        )}
                        
                      </button>
                    )}
                    <button
                    onClick={() => openJobProcessInNewTab(id)} // Pass the jobId to the function
                    className="bg-blue-500 text-white px-2 py-1 rounded flex items-center space-x-2"
                    title="Open Job Process"
                  >
                    <FaExternalLinkAlt />                   </button>
                    {/* Display score if available */}
                    {job.ats_skills && job.ats_skills.score && (
                        <span className="ml-2 px-2 py-1 bg-red-500 text-white rounded">
                          {job.ats_skills.score}
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="mt-2 sm:mt-0 flex flex-wrap items-center">
                    {/* Show Download DOC button if status is "success" */}
                    {job.status === 'success' && (
                      <a
                        href={`https://admin.aracademy360.com/api/resumes/download/${filename}.docx`}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 m-1 flex items-center space-x-2"
                      >
                        <FaDownload className="mr-2" /> Resume
                      </a>
                    )}
                  </div><div className="mt-2 sm:mt-0 flex flex-wrap items-center">
                    {/* Show Download DOC button if status is "success" */}
                    {job.status === 'success' && (
                      <a
                        href={`https://admin.aracademy360.com/api/resumes/download/${filename}_CL.docx`}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200 m-1 flex items-center space-x-2"
                      >
                        <FaDownload className="mr-2" /> Cover
                      </a>
                    )}
                  </div>
                </div>
                
                <div className={`p-4 bg-white ${accordionOpen === id ? 'block' : 'hidden'}`}>
                  
                  {/* View Job button first */}
                  <button
                    onClick={() => window.open(job.jobLink, '_blank')}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none flex items-center space-x-2"
                    >
                      <FaEye  /> <span>View Job</span>
                      </button>
                  
                  {/* Display ATS Skills Table if available */}
                  {job.ats_skills && (
                    <div className="mt-4">
                      <h3 className="text-lg font-semibold mb-2">ATS Skills Analysis</h3>
                      <table className="w-full text-left table-auto border-collapse">
                        <thead>
                          <tr>
                            <th className="border px-4 py-2">Skill Type</th>
                            <th className="border px-4 py-2">Matching Skills</th>
                            <th className="border px-4 py-2">Missing Skills</th>
                            <th className="border px-4 py-2">Required Skills</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border px-4 py-2">Hard Skills</td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.matching_skills?.hard_skills || []).join(', ')}
                            </td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.missing_skills?.hard_skills || []).join(', ')}
                            </td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.required_skills?.hard_skills || []).join(', ')}
                            </td>
                          </tr>
                          <tr>
                            <td className="border px-4 py-2">Soft Skills</td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.matching_skills?.soft_skills || []).join(', ')}
                            </td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.missing_skills?.soft_skills || []).join(', ')}
                            </td>
                            <td className="border px-4 py-2">
                              {(job.ats_skills.required_skills?.soft_skills || []).join(', ')}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}


                  <p className="text-gray-800 mt-2">
                  <EditableField value={job.jobTitle} field="jobTitle" jobId={id} />
                  </p>
                  <p className="text-gray-800 mt-2">
                  <EditableField value={job.companyName} field="companyName" jobId={id} />
                  </p>
                  {/* Other items at the top */}
                  <p className="text-gray-800 mt-2">
                    <b>Skills:</b> {job.skills ? job.skills.join(', ') : 'N/A'}
                  </p>
                  <p className="text-gray-800 mt-2">
                    <b>Mandatory Requirements:</b>{' '}
                    {job.mandatoryRequirements ? job.mandatoryRequirements.join(', ') : 'N/A'}
                  </p>
                  <p className="text-gray-600 mt-2">
                  <b>Added:</b> {formatRelativeTime(job.datePosted, job.timestamp)}
                  </p>

                  {/* Show Progress if job is processing */}
                  {processingJobId === id && (
                    <div className="mt-4">
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className="bg-blue-600 h-2.5 rounded-full"
                          style={{ width: `${progress[id] || 0}%` }}
                        ></div>
                      </div>
                      <p className="text-center mt-2">{progress[id] || 0}% completed</p>
                    </div>
                  )}

                  {/* Description at the end with highlighted search terms */}
                  <p className="text-gray-800 mt-4">
                    <b>Description:</b> <EditableField value={job.jobDescription} field="jobDescription" jobId={id} />
                    </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-gray-500 text-center">No jobs available.</p>
        )}

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              Previous
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`mx-1 px-3 py-1 rounded ${
                currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-gray-200 hover:bg-gray-300'
              }`}
            >
              Next
            </button>
          </div>
        )}
      </div>
      </div>
      </div>
  );
};

export default ListPage;
