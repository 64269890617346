import React, { useState, useEffect } from 'react';

const ResumeTable = () => {
  const [resumes, setResumes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortedResumes, setSortedResumes] = useState([]);
  const [sortAsc, setSortAsc] = useState(true);

  useEffect(() => {
    fetch('https://admin.aracademy360.com/api/resumes')
      .then(response => response.json())
      .then(data => {
        setResumes(data);
        setSortedResumes(data);
      })
      .catch(error => console.error('Error fetching resumes:', error));
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = resumes.filter(resume =>
      resume.name.toLowerCase().includes(query)
    );
    setSortedResumes(filtered);
  };

  const handleSort = () => {
    const sorted = [...sortedResumes].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return sortAsc ? dateA - dateB : dateB - dateA;
    });
    setSortedResumes(sorted);
    setSortAsc(!sortAsc);
  };

  return (
    <div className="resume-table">
      <h2 className="text-xl font-bold mb-4">Resume Documents</h2>
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search by file name"
          value={searchQuery}
          onChange={handleSearch}
          className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none w-full max-w-xs"
        />
        <button
          onClick={handleSort}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Sort by Date {sortAsc ? '↓' : '↑'}
        </button>
      </div>
      <table className="min-w-full bg-white shadow-md rounded">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">File Name</th>
            <th className="py-2 px-4 border-b">Date Created</th>
            <th className="py-2 px-4 border-b">Download</th>
          </tr>
        </thead>
        <tbody>
          {sortedResumes.map((resume) => (
            <tr key={resume.name}>
              <td className="py-2 px-4 border-b">{resume.name}</td>
              <td className="py-2 px-4 border-b">
                {new Date(resume.created_at).toLocaleString()}
              </td>
              <td className="py-2 px-4 border-b">
  <div className="flex space-x-2">
    <a
      href={`https://admin.aracademy360.com/api/resumes/download/${resume.name}`}
      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-200"
      download
    >
      DOC
    </a>
    <a
      href={`https://admin.aracademy360.com/api/resumes/download_pdf/${resume.name}`}
      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-200"
      download
    >
      PDF
    </a>
  </div>
</td>
            </tr>
          ))}
          {sortedResumes.length === 0 && (
            <tr>
              <td className="py-2 px-4 border-b text-center" colSpan="3">
                No resumes found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ResumeTable;
