import React from 'react';
import { useDrop } from 'react-dnd';

const DroppableInterval = ({ interval, onDropTask, children }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'TASK',
    drop: (item) => onDropTask(item.taskData, interval),
    canDrop: (item) => item.fromInterval !== interval, // Prevent dropping on the same interval
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <div
      ref={drop}
      className="interval-group mb-2 p-2 rounded-lg"
      style={{
        backgroundColor: isOver
          ? canDrop
            ? '#d1fae5' // Light green if can drop
            : '#f8d7da' // Light red if cannot drop
          : 'transparent',
        transition: 'background-color 0.2s ease',
      }}
    >
      {children}
    </div>
  );
};

export default DroppableInterval;
