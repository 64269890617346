// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import AppResume from "./AppResume"; // Import the resume component
import CoverLetter from "./AppCoverletter"; // Import the cover letter component
import ResumeTable from './ResumeTable'; // Import the resume table component
import UploadPage from './UploadPage'; // Import the resume table component
import ListPage from './ListPage'; // Import the resume table component
import { MenuIcon, XIcon } from '@heroicons/react/outline'; // Icons for mobile menu
import ResumeGenerator from "./ResumeGenerator";
import HomePage from "./HomePage";
import TaskLogger from "./TaskLogger";


const App = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        {/* Navigation Bar */}
        <nav className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              {/* Logo or Brand Name */}
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="text-2xl font-bold text-blue-600">
                  Resume Generator
                </Link>
              </div>

              {/* Desktop Menu */}
              <div className="hidden md:flex space-x-4 items-center">
                <Link to="/" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                Resume List Process
                </Link>
                <Link to="/resumes" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                  Outputs
                </Link>
                <Link to="/upload" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                  Upload
                </Link>
                <Link to="/list" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                  
                  Generate
                </Link>
                <Link to="/daily" className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium">
                  
                  Daily
                </Link>
              </div>

              {/* Mobile Menu Button */}
              <div className="flex items-center md:hidden">
                <button onClick={toggleMobileMenu} className="text-gray-700 hover:text-blue-600 focus:outline-none focus:text-blue-600">
                  {isMobileMenuOpen ? (
                    <XIcon className="h-6 w-6" />
                  ) : (
                    <MenuIcon className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Menu */}
          {isMobileMenuOpen && (
            <div className="md:hidden bg-white shadow-lg">
              <Link to="/" onClick={toggleMobileMenu} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
              Resume List
              </Link>
              <Link to="/resumes" onClick={toggleMobileMenu} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                Outputs
              </Link>
              <Link to="/upload" onClick={toggleMobileMenu} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
                Upload
              </Link>
              <Link to="/list" onClick={toggleMobileMenu} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
              Generate
              </Link>
              <Link to="/daily" onClick={toggleMobileMenu} className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
              Daily
              </Link>
            </div>
          )}
        </nav>

        {/* Main Content */}
        <main className="py-6">
          <Routes>
          <Route path="/" element={<HomePage />} />
            <Route path="/list" element={<AppResume />} />
            <Route path="/cover-letter" element={<CoverLetter />} />
            <Route path="/resumes" element={<ResumeTable />} />
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/list" element={<ListPage />} />
            <Route path="/daily" element={<TaskLogger />} />
            <Route path="/resumegenerator/:jobId" element={<ResumeGenerator />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
