import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faCheck,
  faTrash,
  faChevronDown,
  faChevronUp,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { db } from './firebase';
import {
  ref,
  push,
  update,
  remove,
  onValue,
  query,
  orderByChild,
  startAt,
  endAt,
} from 'firebase/database';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DraggableTask from './DraggableTask'; // Import the DraggableTask component
import DroppableInterval from './DroppableInterval'; // Import the DroppableInterval component

// Helper to generate CSV file
const generateCSV = (tasks) => {
  const rows = [
    ['Time Interval', 'Task', 'Status', 'Timestamp'], // CSV Header
  ];

  // Convert tasks to CSV rows
  Object.keys(tasks).forEach((interval) => {
    tasks[interval].forEach((task) => {
      rows.push([
        interval,
        task.task,
        task.status,
        new Date(task.timestamp).toLocaleString(),
      ]);
    });
  });

  return rows.map((row) => row.join(',')).join('\n');
};

// Helper function to download CSV
const downloadCSV = (tasks) => {
  const csvData = generateCSV(tasks);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute(
    'download',
    `tasks_${new Date().toLocaleDateString()}.csv`
  );
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Helper function to generate all 1-hour intervals for a day
const generateHourlyIntervals = () => {
  const intervals = [];
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);
  for (let i = 0; i < 24; i++) {
    // 24 hours in a day
    const hour = new Date(startOfDay.getTime() + i * 60 * 60 * 1000);
    intervals.push(hour.toTimeString().slice(0, 2) + ':00'); // "HH:00" format
  }
  return intervals;
};

// Helper function to generate all 10-minute intervals for a given hour
const generate10MinuteIntervals = (hour) => {
  const intervals = [];
  const baseHour = new Date();
  baseHour.setHours(hour, 0, 0, 0);
  for (let i = 0; i < 6; i++) {
    // 6 intervals in an hour (10-minute intervals)
    const time = new Date(baseHour.getTime() + i * 10 * 60 * 1000);
    intervals.push(time.toTimeString().slice(0, 5)); // "HH:MM" format
  }
  return intervals;
};

// Helper function to round timestamp to the nearest 10-minute interval
const roundToNearest10Minutes = (timestamp) => {
  const date = new Date(timestamp);
  const minutes = Math.floor(date.getMinutes() / 10) * 10;
  date.setMinutes(minutes, 0, 0);
  return date.getTime(); // Return the rounded timestamp in milliseconds
};

const TaskLogger = () => {
  const [task, setTask] = useState('');
  const [status, setStatus] = useState('good');
  const [previousTask, setPreviousTask] = useState('');
  const [timerActive, setTimerActive] = useState(false);
  const [lastLoggedTime, setLastLoggedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [tasks, setTasks] = useState({});
  const [expandedHours, setExpandedHours] = useState({}); // Track which hours are expanded
  const [editTask, setEditTask] = useState(null); // Track task being edited
  const [taskStats, setTaskStats] = useState({
    good: 0,
    neutral: 0,
    bad: 0,
  });
  const [addingTasks, setAddingTasks] = useState({}); // Track which intervals are adding tasks

  // New state for To-Do list items
  const [todos, setTodos] = useState({});
  const [newTodo, setNewTodo] = useState(''); // New todo input field
  
  const timerRef = useRef(null);

  // Function to save task to Firebase with a flat structure
  const saveTask = (taskToSave, taskStatus, customTimestamp = null) => {
    const roundedTimestamp = customTimestamp
      ? customTimestamp
      : roundToNearest10Minutes(Date.now());
    const taskRef = ref(db, `tasks`);
    const newTask = {
      timestamp: roundedTimestamp,
      task: taskToSave,
      status: taskStatus,
    };

    // Push the task as a new entry with a unique key under the `tasks` node
    push(taskRef, newTask)
      .then(() => console.log('Task saved:', newTask))
      .catch((error) => console.error('Error saving task:', error));

    setLastLoggedTime(roundedTimestamp);
    setPreviousTask(taskToSave);
  };

  // Auto-extend previous task if no new log
  useEffect(() => {
    if (
      lastLoggedTime &&
      Date.now() - lastLoggedTime >= 10 * 60 * 1000 &&
      !timerActive
    ) {
      saveTask(previousTask, status);
    }
  }, [lastLoggedTime, previousTask, status, timerActive]);

  const startTimer = () => {
    setTimerActive(true);
    setLastLoggedTime(Date.now());

    timerRef.current = setInterval(() => {
      if (timerActive) {
        saveTask(task || previousTask, status);
      }
    }, 10 * 60 * 1000); // 10 minutes interval
  };

  const stopTimer = () => {
    setTimerActive(false);
    clearInterval(timerRef.current);
    saveTask(task || previousTask, status);
  };

  
  // Fetch tasks and todos for selected date
  useEffect(() => {
    const fetchDailyTasksAndTodos = () => {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(startOfDay);
      endOfDay.setDate(startOfDay.getDate() + 1);

      const startTimestamp = startOfDay.getTime();
      const endTimestamp = endOfDay.getTime();

      // Query for daily tasks
      const dailyTasksQuery = query(ref(db, 'tasks'), orderByChild('timestamp'), startAt(startTimestamp), endAt(endTimestamp));
      onValue(dailyTasksQuery, (snapshot) => {
        const tasksData = {};
        let goodCount = 0, neutralCount = 0, badCount = 0;

        snapshot.forEach((childSnapshot) => {
          const task = childSnapshot.val();
          const taskId = childSnapshot.key;
          const roundedTime = new Date(task.timestamp).toTimeString().slice(0, 5);

          if (!tasksData[roundedTime]) {
            tasksData[roundedTime] = [];
          }
          tasksData[roundedTime].push({ ...task, id: taskId });

          if (task.status === 'good') goodCount++;
          if (task.status === 'neutral') neutralCount++;
          if (task.status === 'bad') badCount++;
        });

        setTasks(tasksData);
        setTaskStats({ good: goodCount, neutral: neutralCount, bad: badCount });
      });

      // Query for daily todos
      const todosRef = ref(db, `todos/${selectedDate.toDateString()}`);
      onValue(todosRef, (snapshot) => {
        const todosData = snapshot.val() || {};
        setTodos(todosData);
      });
    };

    fetchDailyTasksAndTodos();
  }, [selectedDate]);

  const toggleAddTaskForm = (interval) => {
    setAddingTasks((prev) => ({
      ...prev,
      [interval]: prev[interval] ? null : { text: '', status: 'good' },
    }));
  };


  // Add new To-Do item for the selected day
  const addTodo = () => {
    if (newTodo.trim()) {
      const todoRef = ref(db, `todos/${selectedDate.toDateString()}`);
      const newTodoItem = { text: newTodo, completed: false };
      push(todoRef, newTodoItem)
        .then(() => {
          setNewTodo(''); // Clear input after adding
        })
        .catch((error) => console.error('Error adding to-do:', error));
    }
  };

  // Toggle completion of a To-Do item
  const toggleTodo = (todoId, completed) => {
    const todoRef = ref(db, `todos/${selectedDate.toDateString()}/${todoId}`);
    update(todoRef, { completed: !completed }).catch((error) =>
      console.error('Error updating to-do:', error)
    );
  };

  // Delete a To-Do item
  const deleteTodo = (todoId) => {
    const todoRef = ref(db, `todos/${selectedDate.toDateString()}/${todoId}`);
    remove(todoRef).catch((error) => console.error('Error deleting to-do:', error));
  };


  const handleEdit = (interval, taskIndex, updatedTask) => {
    const taskToUpdate = tasks[interval][taskIndex];
    const taskRef = ref(db, `tasks/${taskToUpdate.id}`);
    update(taskRef, updatedTask);
    setEditTask(null);
  };

  const handleDelete = (interval, taskIndex) => {
    const taskToDelete = tasks[interval][taskIndex];
    if (
      taskToDelete &&
      window.confirm('Are you sure you want to delete this task?')
    ) {
      const taskRef = ref(db, `tasks/${taskToDelete.id}`);
      remove(taskRef);
      setTasks((prevTasks) => {
        const newTasks = { ...prevTasks };
        newTasks[interval].splice(taskIndex, 1); // Remove the task from the array
        if (newTasks[interval].length === 0) {
          delete newTasks[interval];
        }
        return newTasks;
      });
    }
  };

  const handleSave = () => {
    const newTask = {
      timestamp: roundToNearest10Minutes(new Date().getTime()),
      task: editTask.task,
      status: editTask.status,
    };

    const newTaskRef = ref(db, `tasks/${editTask.id}`);
    update(newTaskRef, newTask)
      .then(() => console.log('Edited task saved:', newTask))
      .catch((error) => console.error('Error saving edited task:', error));

    setTasks((prevTasks) => {
      const newTasks = { ...prevTasks };
      newTasks[editTask.time][editTask.index] = {
        ...newTask,
        id: editTask.id,
      };
      return newTasks;
    });
    setEditTask(null);
  };

  // Handle toggling the visibility of 10-minute intervals for each hour
  const toggleHour = (hour) => {
    setExpandedHours((prev) => ({
      ...prev,
      [hour]: !prev[hour],
    }));
  };

  // Helper function to get the current hour in "HH:00" format
  const getCurrentHour = () => {
    const currentDate = new Date();
    return currentDate.toTimeString().slice(0, 2) + ':00';
  };

  // Function to add a new task to the selected hour/interval
  const handleAddTask = (hour, interval) => {
    const [hourStr, minuteStr] = interval.split(':');
    const hourNum = parseInt(hourStr, 10);
    const minuteNum = parseInt(minuteStr, 10);

    // Construct the timestamp based on selectedDate and interval time
    const taskDate = new Date(selectedDate);
    taskDate.setHours(hourNum, minuteNum, 0, 0);
    const timestamp = taskDate.getTime();

    const newTask = {
      timestamp: timestamp,
      task: addingTasks[interval].text,
      status: addingTasks[interval].status,
    };

    const taskRef = ref(db, `tasks`);
    push(taskRef, newTask)
      .then(() => {
        console.log('New task added:', newTask);
        // Clear the adding state for this interval
        setAddingTasks((prev) => {
          const updated = { ...prev };
          delete updated[interval];
          return updated;
        });
        // Optionally, you can reset the input fields here
      })
      .catch((error) => console.error('Error adding task:', error));
  };

  // State to handle the new task input
  const [newTaskText, setNewTaskText] = useState('');
  const [newTaskStatus, setNewTaskStatus] = useState('good'); // Default to "good" status

  // Automatically expand the current hour when the accordion is first rendered
  useEffect(() => {
    const currentHour = getCurrentHour();
    setExpandedHours((prev) => ({
      ...prev,
      [currentHour]: true, // Automatically expand the current hour
    }));
  }, []);

  const hourlyIntervals = generateHourlyIntervals(); // Generate hourly intervals for the day
  const currentHour = getCurrentHour();
  const sortedHours = [
    currentHour,
    ...hourlyIntervals.filter((hour) => hour !== currentHour),
  ];

  // Handle dropping a task onto a new interval
  const handleDropTask = (taskData, targetInterval) => {
    // Create a duplicate task with the target interval's timestamp
    const [hourStr, minuteStr] = targetInterval.split(':');
    const hourNum = parseInt(hourStr, 10);
    const minuteNum = parseInt(minuteStr, 10);

    // Construct the timestamp based on selectedDate and target interval time
    const taskDate = new Date(selectedDate);
    taskDate.setHours(hourNum, minuteNum, 0, 0);
    const timestamp = taskDate.getTime();

    const duplicatedTask = {
      timestamp: timestamp,
      task: taskData.task,
      status: taskData.status,
    };

    // Save the duplicated task to Firebase
    saveTask(duplicatedTask.task, duplicatedTask.status, duplicatedTask.timestamp);
  };

  return (
    <div className="flex">
      {/* Right Side: Daily Task Tracker */}
      <div className="flex-2 w-full p-4">
        
        <h3 className="text-2xl font-semibold text-center mb-4 text-gray-800">
          Daily Task Tracker
        </h3>
        
        {/* Task Summary */}
        <div className="bg-gray-50 p-4 rounded shadow-sm mb-6">
          <h4 className="text-lg font-semibold">Summary</h4>
          <p className="text-green-500">Good: {taskStats.good} hours</p>
          <p className="text-yellow-500">Neutral: {taskStats.neutral} hours</p>
          <p className="text-red-500">Bad: {taskStats.bad} hours</p>
        </div>

        {/* CSV Export Button */}
        <button
          onClick={() => downloadCSV(tasks)}
          className="mb-6 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg shadow-md transition-all"
        >
          Export to CSV
        </button>

        {/* Daily To-Do List */}
        <div className="bg-gray-50 p-4 rounded shadow-sm mb-6">
          <h4 className="text-lg font-semibold">To-Do List for {selectedDate.toDateString()}</h4>
          <div className="mt-4 space-y-2">
            {/* Display each To-Do item */}
            {Object.keys(todos).length ? (
              Object.entries(todos).map(([id, todo]) => (
                <div key={id} className="flex items-center space-x-2">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`cursor-pointer ${todo.completed ? 'text-green-500' : 'text-gray-400'}`}
                    onClick={() => toggleTodo(id, todo.completed)}
                  />
                  <span className={`flex-1 ${todo.completed ? 'line-through text-gray-500' : ''}`}>
                    {todo.text}
                  </span>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer text-red-500"
                    onClick={() => deleteTodo(id)}
                  />
                </div>
              ))
            ) : (
              <p className="text-gray-500 italic">No to-dos for this day</p>
            )}
          </div>
          {/* Input to add a new To-Do item */}
          <div className="mt-4 flex items-center">
            <input
              type="text"
              value={newTodo}
              onChange={(e) => setNewTodo(e.target.value)}
              placeholder="Add new to-do"
              className="border border-gray-300 rounded-lg px-4 py-2 mr-2 flex-1"
            />
            <button
              onClick={addTodo}
              className="bg-blue-500 text-white px-3 py-2 rounded-lg shadow-md hover:bg-blue-600 transition-colors flex items-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
            </button>
          </div>
        </div>

        {/* Accordion with Tasks */}
        <div className="accordion">
          {sortedHours.map((hour) => {
            const isExpanded = expandedHours[hour];
            return (
              <div
                key={hour}
                className="hour-group mb-4 border border-gray-200 rounded-lg"
              >
                {/* Accordion Header */}
                <div
                  className={`cursor-pointer flex items-center justify-between p-4 transition-colors ${
                    hour === currentHour ? 'bg-yellow-100' : 'bg-gray-100'
                  } hover:bg-yellow-50`}
                  onClick={() => toggleHour(hour)}
                >
                  <span className="text-lg font-bold text-gray-700">{hour}</span>
                  <FontAwesomeIcon
                    icon={isExpanded ? faChevronUp : faChevronDown}
                    className="text-gray-500"
                  />
                </div>

                {/* Expandable Content */}
                {isExpanded && (
                  <div
                    className="pl-6 pr-6 pt-4 pb-2 transition-all duration-300 ease-in-out"
                    style={{ backgroundColor: '#f9f9f9' }}
                  >
                    {/* New Task Input Section */}
                    {/* <div className="flex items-center mb-4">
                      <input
                        type="text"
                        value={newTaskText}
                        onChange={(e) => setNewTaskText(e.target.value)}
                        placeholder="Add new task"
                        className="border border-gray-300 rounded-lg px-4 py-2 mr-2 focus:border-blue-500 focus:outline-none flex-1"
                      />
                      <select
                        value={newTaskStatus}
                        onChange={(e) => setNewTaskStatus(e.target.value)}
                        className="border border-gray-300 rounded-lg px-3 py-2 mr-2 text-gray-600"
                      >
                        <option value="good">Good</option>
                        <option value="neutral">Neutral</option>
                        <option value="bad">Bad</option>
                      </select>
                      <button
                        onClick={() => {
                          // Only add task if text is not empty
                          if (newTaskText.trim() !== '') {
                            handleAddTask(hour, hour); // Assign to the whole hour interval
                            setNewTaskText('');
                            setNewTaskStatus('good');
                          }
                        }}
                        className="bg-blue-500 text-white px-3 py-2 rounded-lg shadow-md hover:bg-blue-600 transition-colors flex items-center"
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                      </button>
                    </div> */}

                    {/* Task Display in 10-Minute Intervals */}
                    {generate10MinuteIntervals(parseInt(hour, 10)).map(
                      (interval) => (
                        <DroppableInterval
                          key={interval}
                          interval={interval}
                          onDropTask={handleDropTask}
                        >
                          <div className="font-semibold text-gray-600 mb-2 flex justify-between items-center">
                            <span>{interval}</span>
                            <button
                              onClick={() => toggleAddTaskForm(interval)}
                              className="text-blue-500 hover:text-blue-600 text-sm"
                            >
                              <FontAwesomeIcon icon={faPlus} /> Add Task
                            </button>
                          </div>

                          {/* Task List with Conditional Background Colors */}
                          {tasks[interval] ? (
                            tasks[interval].map((taskData, index) => (
                              <DraggableTask
                                key={taskData.id}
                                taskData={taskData}
                                index={index}
                                interval={interval}
                                onEdit={(interval, index, taskData) =>
                                  setEditTask({
                                    time: interval,
                                    index,
                                    task: taskData.task,
                                    status: taskData.status,
                                    id: taskData.id,
                                  })
                                }
                                onDelete={handleDelete}
                              />
                            ))
                          ) : (
                            <div className="text-gray-500 italic">
                              No tasks for this interval
                            </div>
                          )}

                          {/* Add Task Form */}
                          {addingTasks[interval] && (
                            <div className="add-task-form flex items-center mt-2">
                              <input
                                type="text"
                                value={addingTasks[interval].text}
                                onChange={(e) =>
                                  setAddingTasks((prev) => ({
                                    ...prev,
                                    [interval]: {
                                      ...prev[interval],
                                      text: e.target.value,
                                    },
                                  }))
                                }
                                placeholder="New task"
                                className="border border-gray-300 rounded-lg px-4 py-2 mr-2 flex-1"
                              />
                              <select
                                value={addingTasks[interval].status}
                                onChange={(e) =>
                                  setAddingTasks((prev) => ({
                                    ...prev,
                                    [interval]: {
                                      ...prev[interval],
                                      status: e.target.value,
                                    },
                                  }))
                                }
                                className="border border-gray-300 rounded-lg px-3 py-2 mr-2 text-gray-600"
                              >
                                <option value="good">Good</option>
                                <option value="neutral">Neutral</option>
                                <option value="bad">Bad</option>
                              </select>
                              <button
                                onClick={() => {
                                  if (
                                    addingTasks[interval].text.trim() !== ''
                                  ) {
                                    handleAddTask(hour, interval);
                                  }
                                }}
                                className="bg-blue-500 text-white px-3 py-2 rounded-lg shadow-md hover:bg-blue-600 transition-colors flex items-center mr-2"
                              >
                                <FontAwesomeIcon icon={faPlus} className="mr-1" />{' '}
                                Add
                              </button>
                              <button
                                onClick={() => toggleAddTaskForm(interval)}
                                className="text-gray-500 hover:text-gray-600"
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </DroppableInterval>
                      )
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        
        <Calendar
         onChange={setSelectedDate} value={selectedDate} />
      </div>
      
    </div>
  );
};

export default TaskLogger;
