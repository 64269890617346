import React, { useState } from "react";

const AppCoverletter = () => {
  const [jobDescription, setJobDescription] = useState("");
  const [yamlContent, setYamlContent] = useState("");
  const [coverLetterName, setCoverLetterName] = useState("");
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [docUrl, setDocUrl] = useState(null);

  const handleJobDescriptionChange = (e) => {
    setJobDescription(e.target.value);
    setError(null);
  };

  const handleYamlContentChange = (e) => {
    setYamlContent(e.target.value);
    setError(null);
  };



  const handleCoverLetterNameChange = (e) => {
    setCoverLetterName(e.target.value);
  };

  const generateCoverLetter = async () => {
    if (!jobDescription || !coverLetterName) {
      setError("Both fields are required");
      return;
    }

    try {
      const response = await fetch("https://admin.aracademy360.com/generate_coverletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_description: jobDescription,
          name_of_coverletter: coverLetterName,
          yamlContent: yamlContent,
        }),
      });

      const result = await response.json();
      if (result.success) {
        setMessage("Cover letter generated successfully!");
        setDocUrl(result.downloadUrl);
      } else {
        setError(result.message);
      }
    } catch (e) {
      setError("Failed to generate cover letter.");
    }
  };

  return (
    <div className="min-h-screen w-screen flex flex-col items-center justify-center m-0 p-0">
      <h2 className="text-2xl font-bold mb-4">Cover Letter Generator</h2>

      <div className="w-full">
        <input
          type="text"
          placeholder="Enter name for cover letter"
          value={coverLetterName}
          onChange={handleCoverLetterNameChange}
          className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mb-4"
        />

<textarea
          placeholder="Enter Yaml Content (option)"
          value={yamlContent}
          style={{ minHeight: "50px" }}
          onChange={handleYamlContentChange}
          className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full h-40 mb-4"
        />
        <textarea
          placeholder="Enter job description"
          value={jobDescription}
          style={{ minHeight: "400px" }}
          onChange={handleJobDescriptionChange}
          className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full h-40 mb-4"
        />

        <div className="flex space-x-2">
          <button
            onClick={generateCoverLetter}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Generate Cover Letter
          </button>
        </div>

        {error && <p className="text-red-500 mt-2">{error}</p>}
        {message && <p className="text-green-500 mt-2">{message}</p>}

        {docUrl && (
          <a href={docUrl} download className="mt-4">
            <button className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none">
              Download Cover Letter
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default AppCoverletter;
