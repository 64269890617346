import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-github";

import { useNavigate } from 'react-router-dom';
const AppResume = () => {
  const [jobDescription, setJobDescription] = useState("");
  const [fileName, setFileName] = useState("");
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [existingResumes, setExistingResumes] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    // Fetch existing resumes
    fetch('https://admin.aracademy360.com/api/resumes_list')
      .then(response => response.json())
      .then(data => {
        const resumeNames = data.map(resume => resume.name.replace('.docx', '')); // Remove .docx extension
        setExistingResumes(resumeNames);
      })
      .catch(error => {
        console.error('Error fetching existing resumes:', error);
      });
  }, []);
  const goToResumes = () => {
navigate('/resumes');
  };
  const handleJobDescriptionChange = (newContent) => {
    setJobDescription(newContent);
    setError(null);
  };

  const handleFileNameChange = (e) => {
    const name = e.target.value;
    setFileName(name);
    setError(null);

    if (existingResumes.includes(name)) {
      setWarning(`A resume with the name "${name}" already exists. Proceeding will overwrite it.`);
    } else {
      setWarning("");
    }
  };

  const tailorResume = async () => {
    if (!fileName) {
      setError("File name is required");
      return;
    }

    if (!jobDescription) {
      setError("Job description is required");
      return;
    }

    // Reset error and warning messages
    setError(null);
    setMessage("");

    setIsLoading(true);
    setProgress(0);

    try {
      const response = await fetch("https://admin.aracademy360.com/tailor_resume", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          job_description: jobDescription,
          output_filename: fileName,
        }),
      });

      const result = await response.json();
      if (result.status === "success") {
        const eventSource = new EventSource(`https://admin.aracademy360.com/progress/${result.task_id}`);
        
        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          setProgress(data.progress);
          
          if (data.progress === 100) {
            eventSource.close();
            setIsLoading(false);
            setMessage("Resume tailoring completed successfully!");

            // Update existingResumes to include the new file
            setExistingResumes(prevResumes => {
              if (!prevResumes.includes(fileName)) {
                return [...prevResumes, fileName];
              }
              return prevResumes;
            });
          }
        };

        eventSource.onerror = () => {
          eventSource.close();
          setIsLoading(false);
          setError("Error occurred while tailoring resume");
        };
      } else {
        setIsLoading(false);
        setError(result.message);
      }
    } catch (e) {
      setIsLoading(false);
      setError("Failed to start resume tailoring.");
    }
  };

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-start p-4 sm:p-6 md:p-8">
      <h2 className="text-xl sm:text-2xl font-bold mb-4">Resume Tailoring</h2>

      <div className="w-full max-w-3xl">
        <input
          type="text"
          placeholder="Enter output file name (without extension)"
          value={fileName}
          onChange={handleFileNameChange}
          className="p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full mb-4"
        />

        <div className="w-full mb-4">
          <AceEditor
            mode="text"
            theme="github"
            onChange={handleJobDescriptionChange}
            value={jobDescription}
            name="jobDescriptionEditor"
            editorProps={{ $blockScrolling: true }}
            style={{ width: "100%", height: "50vh" }}
            className="shadow-sm border border-gray-300 rounded"
            setOptions={{
              wrap: true,
            }}
            placeholder="Enter job description here..."
          />
        </div>


        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        {warning && <p className="text-yellow-500 mb-2 text-center">{warning}</p>}
        {message && (
          <div className="text-center mb-2">
            <p className="text-green-500">{message}</p>
            <button onClick={goToResumes}             className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full sm:w-auto"
            >
              Go to Resumes
            </button>
          </div>
        )}

        <div className="flex justify-center mb-4">
          <button
            onClick={tailorResume}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none w-full sm:w-auto"
            disabled={isLoading}
          >
            {isLoading ? "Tailoring..." : "Tailor Resume"}
          </button>
        </div>

        {isLoading && (
          <div className="mb-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{width: `${progress}%`}}
              ></div>
            </div>
            <p className="text-center mt-2">{progress}% completed</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppResume;
