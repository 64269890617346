import React, { useState } from 'react';
import { db } from './firebase';  // Import the database instance
import { ref, push, get, query, orderByChild, equalTo } from 'firebase/database';  // Import required Firebase methods

const UploadPage = () => {
  const [jsonContent, setJsonContent] = useState('');

  const handleUpload = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      setJsonContent(e.target.result);
    };
  };

  const checkForDuplicate = async (jobLink) => {
    const jobsRef = ref(db, 'jobs');  // Reference to 'jobs' node in Firebase
    const jobQuery = query(jobsRef, orderByChild('jobLink'), equalTo(jobLink));  // Query jobs where jobLink matches
    const snapshot = await get(jobQuery);  // Fetch the query result
    return snapshot.exists();  // Return true if jobLink exists, false otherwise
  };

  const handleSubmit = async () => {
    try {
      const data = JSON.parse(jsonContent); // Parse JSON content

      // Check if the data contains an array of jobs or a single job
      const jobsArray = Array.isArray(data) ? data : [data];

      let duplicates = [];
      let addedJobs = [];

      for (let job of jobsArray) {
        // Check if the jobLink already exists in the database
        const isDuplicate = await checkForDuplicate(job.jobLink);
        if (!isDuplicate) {
          // If not a duplicate, push the job to the database
          const jobsRef = ref(db, 'jobs');
          await push(jobsRef, {
            ...job,
            status: 'pending',        // Set initial status to 'pending'
            timestamp: Date.now()      // Add timestamp for when the job is uploaded
          });
          addedJobs.push(job.jobTitle);
        } else {
          // Track duplicates
          duplicates.push(job.jobTitle);
        }
      }

      if (addedJobs.length > 0) {
        alert(`Successfully added the following jobs: ${addedJobs.join(', ')}`);
      }

      if (duplicates.length > 0) {
        alert(`Skipped duplicate jobs: ${duplicates.join(', ')}`);
      }
    } catch (error) {
      console.error('Invalid JSON format', error);
      alert('Invalid JSON format. Please check the data and try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold mb-6 text-center">Upload Job Data</h1>
      <div className="w-full max-w-md bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Upload JSON File:</label>
          <input
            type="file"
            onChange={handleUpload}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Or Paste JSON Here:</label>
          <textarea
            value={jsonContent}
            onChange={(e) => setJsonContent(e.target.value)}
            placeholder="Paste your JSON here"
            rows="10"
            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          onClick={handleSubmit}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UploadPage;
