
  
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBaN6X_537IIyDnMOg6acoRQaRNBx7FCV4",
    authDomain: "resumegenerator-a632c.firebaseapp.com",
    databaseURL: "https://resumegenerator-a632c-default-rtdb.firebaseio.com",
    projectId: "resumegenerator-a632c",
    storageBucket: "resumegenerator-a632c.appspot.com",
    messagingSenderId: "787400998378",
    appId: "1:787400998378:web:d6500b66c207a99b8e0693",
    measurementId: "G-Q9X6JWV9F3"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and export it
const db = getDatabase(app);
export { db };