import React, { useState } from 'react';
import { ref, update } from 'firebase/database';
import { db } from './firebase'; // Adjust this path as needed

const EditableField = ({ value, field, jobId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(value);

  const handleBlur = async () => {
    setIsEditing(false);
    if (newValue !== value) {
      const jobRef = ref(db, `jobs/${jobId}`);
      await update(jobRef, { [field]: newValue });
    }
  };

  return (
    <div className="w-full">
      {isEditing ? (
        // Tailwind styling for input field in edit mode
        <input
          type="text"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          className="border border-gray-300 rounded p-2 w-full focus:ring-2 focus:ring-blue-500"
        />
      ) : (
        // Tailwind styling for display mode
        <span
          onClick={() => setIsEditing(true)}
          className="bg-gray-100 p-2 rounded cursor-pointer hover:bg-gray-200 transition-colors duration-200 block"
        >
          {value || <span className="text-gray-400">Click to edit</span>}
        </span>
      )}
    </div>
  );
};

export default EditableField;
