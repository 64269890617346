import React from 'react';
import { useSearchParams } from 'react-router-dom'; // For query parameters
import ListPage from './ListPage';
import ResumeGenerator from './ResumeGenerator';

const HomePage = () => {
    const [searchParams] = useSearchParams(); // Get query parameters
    const jobId = searchParams.get('jobId'); // Get 'job' from query parameters
  
  // Conditionally render ResumeGenerator if jobId is present, otherwise render ListPage
  return jobId ? <ResumeGenerator jobId={jobId} /> : <ListPage />;
};

export default HomePage;
