import React from 'react';
import { useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const DraggableTask = ({ taskData, index, interval, onEdit, onDelete }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TASK',
    item: { taskData, fromInterval: interval },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={`task-item flex justify-between items-center p-3 mb-2 rounded-lg shadow-sm transition-colors ${
        taskData.status === 'good'
          ? 'bg-green-100'
          : taskData.status === 'neutral'
          ? 'bg-yellow-100'
          : 'bg-red-100'
      }`}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <div className="task-view flex justify-between w-full">
        <div>
          <span className="task-text font-medium">{taskData.task}</span>
          <span className="ml-2 text-gray-500">({taskData.status})</span>
        </div>
        <div className="actions flex items-center">
          <button
            onClick={() => onEdit(interval, index, taskData)}
            className="text-blue-500 mr-2 hover:text-blue-600 transition-colors"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <button
            onClick={() => onDelete(interval, index)}
            className="text-red-500 hover:text-red-600 transition-colors"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraggableTask;
